<template>
  <div class="full">
    <div class="head p_f d_f ali_c">
      <div class="back">
        <img src="./img/hc_back.png" alt="" @click="$router.go(-1)">
      </div>
      <div class="top_search p_r d_f ali_c j_s flex_1">
        <input type="text" class="t_a" placeholder="搜索评价..." v-model="content">
        <img src="./img/hc_search.png" alt="" class="cur_p" @click="reloadComment">
      </div>
    </div>
    <div class="hc_content">
      <div class="title">房客评价</div>
      <div class="d_f total_star">
        <van-icon name="star" color="#20C278" size="14"/>
        <span class="score">{{commentArr[0].totalScore.toFixed(2)}}</span>
        <div class="score_cut" style="width: 1px;"></div>
        <span class="total_num">{{commentTotal}}</span>
        <span class="num_text">条评价</span>
      </div>
      <div class="c_content_out">

        <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
          <van-list
            :immediate-check="false"
            v-model="isLoading"
            :finished="finished"
            finished-text="没有更多了"
            @load="onLoadBottom"
          >
            <div class="items comment_out" v-for="(item,index) in commentArr" :key="index">
              <div class="d_f ali_c j_s top_user">
                <div class="user_logo">
                  <van-image round fit="cover" :src="item.headPortrait" />
                </div>
                <div>
                  <div class="user_name">{{item.nickname}}</div>
                  <!--v-model="2"-->
                  <van-rate
                    v-model="item.starScore"
                    :size="12"
                    allow-half
                    color="#20C178"
                    void-icon="star-o"
                    void-color="#20C178"
                  />
                </div>
                <div class="flex_1 t_r right_text">
                  <div>&nbsp;</div>
                  <div>{{item.criticismDate}}</div>
                </div>
              </div>
              <div class="text_out" :class="item.state?'auto_height':''">
                <div class="user_text" :class="item.state?'':'text_over6'" :ref="'textH'+ index">{{item.content}}</div>
              </div>
              <!--<div class="user_text" :class="item.state?'cd_cont_state':''">{{item.content}}</div>-->
              <div class="cd_btn" v-if="item.stateBtn" @click="changeState(item,index)">
                <!--<div class="cd_btn cur_p" @click="changeState(item,index)">-->
                <span>{{item.state ? '收起':'展开'}}</span>
              </div>
              <div class="imgs_out clear">
                <van-image fit="cover" :src="img.url" alt="" v-for="(img,i) in item.attachments" :key="i" @click="showImg(item,i)"/>
              </div>
              <div class="store_out" v-if="item.child.length">
                <div class="store_text_out" :class="item.child[0].storeState?'auto_height':''">
                  <div class="store_content" :class="item.child[0].storeState?'':'text_over2'" :ref="'storeH'+ index">商家回复：{{item.child[0].content}}</div>
                </div>
                <div class="cd_btn cur_p cd_store" @click="changeStateStore(item.child[0])"  v-if="item.child[0].stateStoreBtn">
                  <span>{{item.child[0].storeState ? '收起':'展开'}}</span>
                </div>
              </div>
            </div>
          </van-list>
        </van-pull-refresh>
      </div>
    </div>

    <van-image-preview :closeable="true" :startPosition="startPosition" v-model="show" :images="imgs"></van-image-preview>

  </div>
</template>

<script>
  import apis from './utils/apis'
  import { Image, ImagePreview, PullRefresh, List, Rate, Icon} from 'vant'
  export default {
    name: "hotelCommentList",
    components:{
      'van-icon': Icon,
      'van-rate': Rate,
      'van-list': List,
      'van-pull-refresh': PullRefresh,
      'van-image-preview': ImagePreview,
      'van-image': Image,
    },
    data() {
      return {
        sellerId:this.$route.query.id,
        commentArr:[],//评价列表
        commentTotal:0,
        refreshing: false, //是否刷新
        isLoading: false, //是否刷新
        finished: false, //是否刷新
        pageIndex:1,
        pageSize:10,
        content:'',
        startPosition:0,
        show:false,
        imgs:[],
      }
    },
    mounted(){

    },
    created(){
      this.getShareInfo(
        this.$global.shareTitle,
        this.$global.visitUrl,
        this.$global.shareImage,
        this.$global.shareIntro,
      );
      this.getComment();
    },
    methods: {
      showImg(item,i){
        this.imgs = [];
         item.attachments.map(item => {
           this.imgs.push(item.url)
        });
        this.startPosition = i;
        this.show = true;
      },
      //上啦加载
      onLoadBottom(){
        let me = this;
        let page = me.pageIndex;
        page = page ? page + 1 : 1;
        me.pageIndex = page;
        me.getComment();
      },
      //下拉刷新
      onRefresh(){
        let me = this;
        me.commentArr = [];
        me.finished = false;
        me.pageIndex = 1;
        me.commentTotal = 0;
        me.getComment();

      },
      changeStateStore(row){
        row.storeState = !row.storeState;
        this.commentArr = JSON.parse(JSON.stringify(this.commentArr))
      },
      changeState(row,index){
        row.state = !row.state;
        this.commentArr = JSON.parse(JSON.stringify(this.commentArr))
      },
      // 搜索
      reloadComment(){
        this.onRefresh();
      },
      //获取评论
      getComment(){
        let me = this;
        me.isLoading = true;
        me.$post({
          url:apis.slicedQueryUserCriticism,
          params: {
            sellerId: me.$route.query.id,
            bizType: 12,
            token: me.$global.token,
            pageIndex:me.pageIndex,
            pageSize:me.pageSize,
            content:me.content,
          }
        }).then(res => {//thirdName
          me.isLoading = false;
          if (me.refreshing) {
            me.refreshing = false;
          }
          me.commentTotal = res.data.count;
          if (res.data.count) {
            me.commentArr = me.commentArr.concat(res.data.data);
          }
          let list = res.data.data;
          if (!list || list.length < me.pageSize) {
            me.finished = true;
          }
          let _t = this;
          _t.$nextTick(() => {

            me.commentArr.map((item,index) => {
              let str = 'textH'+index;
              let w = window.screen.width,num = 95;
              if(w > 360 && w < 375){
                num = 89
              }else if(w > 330 && w <= 360){
                num = 83
              }else if(w > 310 && w <= 330){
                num = 77
              }else if(w > 300 && w <= 310){
                num = 71
              }
              if(_t.$refs[str][0].offsetHeight>num){
                item.state = false;
                item.stateBtn = true;
              }else{
                item.state = true;
                item.stateBtn = false;
              }
              if(item.child.length){
                let numS = 36;
                if(numS <= 300){
                  numS = 24;
                }else if(num > 300 && num <= 310){
                  numS = 26;
                }else if(num > 310 && num <= 330){
                  numS = 28;
                }else if(num > 330 && num <= 352){
                  numS = 30;
                }else if(num > 352 && num <= 375){
                  numS = 32;
                }else if(num > 375 && num <= 399){
                  numS = 34;
                }
                let str1 = 'storeH'+index;
                if(_t.$refs[str1][0].offsetHeight>numS){
                  item.child[0].storeState = false;
                  item.child[0].stateStoreBtn = true;
                }else{
                  item.child[0].storeState = true;
                  item.child[0].stateStoreBtn = false;
                }
              }
            });
            me.commentArr = JSON.parse(JSON.stringify(me.commentArr))
          })
        });
      },
    }
  }
</script>

<style scoped lang="scss">
  .store_text_out{
    height: 32px;
    overflow: hidden;
    line-height: 16px;
    .store_content{
      font-size: 12px;
      line-height: 16px;
      &.text_over2{
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden;
      }
    }
    &.auto_height{
      height: auto;
    }
  }
  .text_out{
    height: 96px;
    overflow: hidden;
    line-height: 16px;
    &.auto_height{
      height: auto;
    }
  }
  .imgs_out{
    margin-top: 20px;
  }
  .cd_btn.cd_store{
    margin: 0;
    position: relative;
    top: 5px;
  }
  .store_out{
    padding: 16px 8px 16px 8px;
    background: #E9E9E9;
    border-radius: 4px;
    font-size: 12px;
    color: #3E3E3E;
  }
  .imgs_out{
    .van-image{
      float: left;
      margin-right: 10px;
      margin-bottom: 15px;
      width: calc((100% - 20px) / 3);
      height: 105px;
      border-radius: 8px;
      box-shadow: 0 0 5px 1px rgba(6,0,1,0.05);
      &:nth-child(3n){
        margin-right: 0;
      }
    }
  }
  .cd_btn{
    margin-top: 8px;
    font-size: 12px;
    font-weight: bold;
    color: #20C278;
    line-height: 21px;
    /*margin-bottom: 15px;*/
  }
  .cd_cont_state{
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    overflow: hidden;
  }
  .auto_height{
    .text_over6{
      height: 96px;
    }
    .text_over2{
      height: 32px;
    }
  }
  .comment_out{
    .user_text{
      /*margin-bottom: 20px;*/
      font-size: 12px;
      line-height: 16px;
      font-weight: 500;
      color: #3E3E3E;
      &.text_over6{
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 6;
        overflow: hidden;
      }
    }
    .top_user{
      margin-bottom: 20px;
    }
    .right_text{
      font-size: 12px;
      font-weight: 500;
      color: #A0A0A0;
    }
    .user_name{
      font-size: 14px;
      font-weight: bold;
      color: #3E3E3E;
    }
    .user_logo{
      .van-image{
        width: 35px;
        height: 35px;
        margin-right: 10px;
      }
    }
    .co_score{
      span{
        font-size: 14px;
      }

      top: 22px;
      right: 13px;
      font-size: 21px;
      font-weight: bold;
      color: #20C278;
    }
    .co_title{
      margin-bottom: 26px;
      font-size: 17px;
      font-weight: bold;
      color: #3E3E3E;
    }
  }
  .c_content_out{
    margin-top: 20px;
    .items{
      padding: 30px 0;
      border-top: 1px solid #DEDEDE;

    }
  }
  .hc_content{
    .total_star{
      .num_text{
        font-size: 12px;
      }
      .score,.total_num{
        position: relative;
        top: 2px;
      }
      .score{
        /*margin-right: 25px;*/
      }
      .score_cut{
        margin: 0 12px 1px 12px;
        height: 13px;
        background: #DEDEDE;
      }
      font-size: 16px;
      font-weight: bold;
      color: #3E3E3E;
      align-items: flex-end;
    }
    .title{
      font-size: 19px;
      font-weight: 500;
      color: #3E3E3E;
      margin-bottom: 20px;
    }
    padding-top: 30px;
    height: 100%;
    box-sizing: border-box;
    overflow: auto;
  }
  .head{
    .top_search{
      img{
        width: 17px;
      }
      input{
        flex: 1;
        border: none;
        outline: none;
        font-size: 16px;
        color: #312312;
        &::placeholder{
          font-size: 16px;
          font-weight: 500;
          color: #D0D0D0;
        }
      }
      padding: 0 16px;
      overflow: hidden;
      line-height: 35px;
      height: 35px;
      border: 1px solid #8E8E8E;
      border-radius: 18px;
      box-sizing: border-box;
    }
    .back{
      width: 11px;
      margin-right: 8px;
      img{
        width: 100%;
      }
    }
    padding: 0 22px;
    width: 100%;
    left: 0;
    top: 15px;
    box-sizing: border-box;
  }
  .full{
    padding: 50px 22px 15px 22px;
    box-sizing: border-box;
  }
</style>
